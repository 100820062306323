import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../style.scss";
import "../../riot_admin.scss";
import SideBar from "../../vendor/sidebar/index";
import { Input } from "antd";
import VendorHeader from "../../../src/vendor/header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import EditProductModal from "./EditProduct";
import { Select, Form, Upload, Button, DatePicker,notification } from "antd";
import { UploadOutlined, CloseCircleOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import ColorPaletteModal from '../../../src/colorPalaetteModal';

const ProductAddforVendor = () => {
  const token = sessionStorage.getItem("tokenforseller");

  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleUnitSelect = (value) => {
    setUnit(value);
  };
  const [selectedProductTypeId, setSelectedProductTypeId] = useState("");
  const [categorydata, setcategoryData] = useState([]);
  const [category, setcategory] = useState([]);
  const [fileList, setFileList] = useState([]); // To store uploaded files
  const [uploadfile, setUploadfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productname, setProductname] = useState("");
  const [slug, setSlug] = useState("");
  const [sortdescription, setSortdescription] = useState("");
  const [fulldetails, setFulldetails] = useState("");
  const [color, setColor] = useState("");
  const [colorname, setColorname] = useState("");
  const [offerprice, setOfferprice] = useState("");
  const [offerstart, setOfferstart] = useState("");
  const [offerend, setOfferend] = useState("");
  const [productunit, setUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [sellingprice, setSellingprice] = useState("");
  const [mrpprice, setMrpprice] = useState("");
  const [pricesymbol, setPricesymbol] = useState("");
  const [storage, setStorage] = useState("");
  const [size, setSize] = useState("");
  const [weight, setWeight] = useState("");
  const [data, setData] = useState([]);
  const [categoryList, setCategoriesList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] = useState("");
  const [subcategoryList, setSubCategoriesList] = useState([]);
  const [subsubcategoryList, setSubSubCategoriesList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productposition, setProductposition] = useState("");
  const [isOfferPriceValid, setIsOfferPriceValid] = useState(true);
  const [offerPriceErrorMessage, setOfferPriceErrorMessage] = useState("");
  const [producttags, setProducttags] = useState("");
  const [preview, setpreview] = useState("");
  const [clientId, setClientId] = useState(null);
  const [isSellingPriceValid, setIsSellingPriceValid] = useState(true);
  const [sellingPriceErrorMessage, setSellingPriceErrorMessage] = useState("");
  const [display, setDisplaysize] = useState("");
  const [os, setOS] = useState("");
  const [ram, setRam] = useState("");
  const [groupapitypedata, setGroupapitypedata] = useState([]);
  
  const [capacity, setCapacity] = useState("");
  const [bookname, setBookname] = useState("");
  const [author, setAuthor] = useState("");
  const [type, setTypeac] = useState("");
  const [starrating, setStarrating] = useState("");
  const [displayunit, setdisplayunit] = useState("");
  const [capacityunit, setcapacityunit] = useState("")
  const [groupTypeFields, setGroupTypeFields] = useState([]);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [youtube, setYoutube] = useState("");
  const [material, setMaterial] = useState("")
  const [packagecontent, setPackagecontent] = useState("")
  const [packageweight, setPackageweight] = useState("")
  const [packagingunit, setPackagingunit] = useState("")
  const [fragile, setFragile] = useState(false)
  const options = [
    // { value: "bestseller", label: "Best Sellers" },
    { value: "featuredproduct", label: "Featured Product" },
    // Add more options here if needed
  ];
  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing

  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);
  
    try {
  
      const response = await axios.post(
        "https://api.starmallonline.com/api/v1/fileupload/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404 || !token) {
        // Redirect to the login page for 401 or 404 errors or if the token is not present
        // Example redirect: window.location.href = "/login";
        return; // Exit early to prevent further logic execution
      }
  
      setUploadfiles((prevUploadFiles) => [
        ...prevUploadFiles,
        response.data.organiserId,
      ]);
      onSuccess("File uploaded");
    } catch (error) {
      console.error("File upload failed:", error);
      onError("File upload failed");
    }
  };
  
  const handleFileUploadforpreview = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const token = sessionStorage.getItem('tokenforseller');
  
      const response = await axios.post(
        "https://api.starmallonline.com/api/v1/fileupload/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: "Bearer " + token, // Set the Authorization header with the token
            // 'Content-Type': 'application/json',
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        // Redirect to the login page for 401 or 404 errors or if the token is not present
        navigate("/AdminLogin");
        return; // Exit early to prevent further logic execution
      }

      setpreview((prevUploadFiles) => 
        parseInt(response.data.organiserId),
      );
      onSuccess("File uploaded");
    } catch (error) {
      onError("File upload failed");
    }
  };
  useEffect(() => {
    if (!token) {
      return;
    }
    // fetchSubData(selectedCategoryId, selectedSubCategoryId);
  }, [selectedCategoryId, selectedSubCategoryId]);

  useEffect(() => {
    if (clientId) {
      const fetchSubData = async () => {
        try {
          // Construct the URL with query parameters
          const apiUrl = `https://api.starmallonline.com/api/v1/sellercategory/get`;
          const requestBody = {
            sellerid: clientId,
          };

          const response = await axios.post(apiUrl, requestBody, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          });

          if (response.status === 401 || response.status === 404) {
            // navigate("/login");
            return;
          }

          setcategory(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchSubData();
    }
  }, [clientId]);

  // Function to open the edit modal
  const handleEditCategory = (product) => {
    setSelectedCategory(product);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };
  const handleSaveEdit = (editedData) => {
    // Handle the save logic here and update the categories array with the edited data
    // You can use the editedData to make an API call to save the changes
    console.log("Edited data:", editedData);
    // After saving, you can update the categories array and close the modal
    // For example, update the categories array and close the modal as follows:
    // setCategories((prevCategories) => {
    //   const updatedCategories = prevCategories.map((category) => {
    //     if (category.id === selectedCategory.id) {
    //       return { ...category, ...editedData };
    //     }
    //     return category;
    //   });
    //   return updatedCategories;
    // });
    handleCloseEditModal();
  };

  const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (parseFloat(sellingprice) > parseFloat(mrpprice)) {
      // Display error message and return without making the API call
      console.error("Selling price cannot be greater than MRP price");
      return;
    }
    try {
      setLoading(true);
      // TODO: Replace with actual API call
      const response = await fetch(
        "https://api.starmallonline.com/api/v1/product/add",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sellerid:clientId,
            productname,
            slug,
            sortdescription,
            fulldetails,
            quantity: quantity !== null && quantity !== undefined && quantity !== '' ? quantity : '0',
            sellingprice,
            mrpprice,
            categoryid: selectedCategoryId,
            subcategoryid: selectedSubCategoryId,
            subsubcategoryid: selectedSubSubCategoryId,
            uploadfile,
            color: selectedColor,
            colorname,
            size,
            storage,
            weight,
            productunit,
            producttags,
            pricesymbol,
            previeworganiserid:preview,
            ram,
            starrating,
            display,
            displayunit,
            os,
            capacity,
            type,
            author,
            capacityunit,
            material,
            packagecontent,
            packageweight,
            packagingunit,
            fragile,
          }),
        }
      );

      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        // navigate('/login');
        return; // Exit early to prevent further logic execution
      }

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        console.log("POST request successful");
        if (data && data.description) {
          
  notification.success({
    message: data.description,
    duration: 3, // Duration in seconds
  });
          navigate('/ListProductforVendor')
        }
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setData([]); // If no data is received, set an empty array
        }
        setLoading(false);
        // navigate("/product-list");
        if (data.token) {
          localStorage.setItem("token", token);
        }
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!token) {
      return;
    }
    fetchSubData(selectedCategoryId, selectedSubCategoryId);
  }, [selectedCategoryId, selectedSubCategoryId]);

  const fetchSubData = async (categoryid, subcategoryid) => {
    try {
      // Construct the URL with query parameters
      const apiUrl = `https://api.starmallonline.com/api/v1/product/getcategoryAndsubcategory`;
      const requestBody = {
        categoryid: categoryid,
        subcategoryid: subcategoryid,
      };
      const response = await axios.put(apiUrl, requestBody, {
        headers: {
          //   'Authorization': 'Bearer ' + token,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setcategoryData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNameSelect = (categoryid) => {
    setSelectedCategoryId(categoryid);
    if (categorydata && categorydata.length > 0) {
      const subCategoryData = categorydata.find(
        (item) => item.categoryid === categoryid
      );
      setSubCategoriesList(subCategoryData.subcategory);

      if (subCategoryData.producttypeid) {
        getProductTypeDetails(subCategoryData.producttypeid);
      } else {
        // Reset fields if producttypeid is not available
        setGroupTypeFields([]);
      }
      if (subCategoryData != null) {
        setSubCategoriesList(subCategoryData.subcategory || []);
      } else {
        setSubCategoriesList([]);
      }
    }
    
  };
  const getProductTypeDetails = async (producttypeid) => {
    const apiUrl = 'https://api.starmallonline.com/api/v1/producttype/getbyid';
    const requestBody = {
      producttypeid: producttypeid,
      // Add other required parameters if needed
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const productTypeDetails = await response.json();
        console.log('Product Type Details:', productTypeDetails);
        // Process the product type details as needed
        setGroupTypeFields(productTypeDetails.fields);
         await fetchSizeOptions(productTypeDetails.fields); 
      } else {
        console.error('Failed to fetch product type details');
      }
    } catch (error) {
      console.error('Error fetching product type details:', error);
    }
  };
  const fetchSizeOptions = async (fields) => {
    try {
      // Check if 'Size' is included in the fields array
      if (fields.includes('Size')) {
        const response = await fetch('https://api.starmallonline.com/api/v1/droupdown/getbydroupdown', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ droupdown: 'size' }), 
        });
  
        if (response.ok) {
          const data = await response.json();
          setSizeOptions(data);
          console.log('Size Dropdown Options:', data);
          // Process the dropdown options as needed
          return data; // Return the response data
        } else {
          console.error('Failed to fetch size dropdown options');
          return null; // Return null if the request fails
        }
      } else {
        console.log('Size field is not included');
        return null; // Return null if 'Size' field is not included
      }
    } catch (error) {
      console.error('Error fetching size dropdown options:', error);
      return null; // Return null in case of an error
    }
  };
  useEffect(() => {
    // Clear fields when component unmounts
    return () => setGroupTypeFields([]);
  }, []);
  const handleNameSubCategory = (subcategoryid) => {
    setSelectedSubCategoryId(subcategoryid);
    const subsCategoryData = subcategoryList.find(
      (item) => item.subcategoryid === subcategoryid
    );
    if (subsCategoryData != null) {
      setSubSubCategoriesList(subsCategoryData.subsubcategory || []);
    } else {
      setSubSubCategoriesList();
    }
  };
  const handleNameSubSubCategory = (subsubcategoryid) => {
    setSelectedSubSubCategoryId(subsubcategoryid);
  };
  // Event handler to handle option selection
  // Event handler to handle option selection
  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };
  const handleOfferPriceChange = (value) => {
    setOfferprice(value);
    if (!sellingprice) {
      setIsOfferPriceValid(false);
      setOfferPriceErrorMessage("Please enter the selling price first.");
    } else if (parseFloat(value) >= parseFloat(sellingprice)) {
      setIsOfferPriceValid(false);
      setOfferPriceErrorMessage(
        "Offer price cannot be greater than selling price"
      );
    } else {
      setIsOfferPriceValid(true);
      setOfferPriceErrorMessage("");
    }
  };
  const handleMrpPrice = (value) => {
    setSellingprice(value);
    if (parseFloat(value) > parseFloat(mrpprice)) {
      setIsSellingPriceValid(false);
      setSellingPriceErrorMessage("Selling price cannot be greater than MRP price");
    } else {
      setIsSellingPriceValid(true);
      setSellingPriceErrorMessage("");
    }
  }
  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.post("https://api.starmallonline.com/api/v1/producttype/getforadmin",{},{
			
		  headers: {
        Authorization: "Bearer " + token,
        'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setGroupapitypedata(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          'https://api.starmallonline.com/api/v1/login/getuserdetails',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
            },
          }
        );

        if (response.ok) {
            const responseData = await response.json();
            setClientId(responseData.id); // Store the client ID in state
          } else {
            console.error('Error fetching client details');
          }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    fetchClientDetails();
  }, []);
  const [selectedColor, setSelectedColor] = useState('');

  const handleSelectColor = (selectedHex) => {
    setSelectedColor(selectedHex);
  };
    const [selectedGroupType, setSelectedGroupType] = useState("");
    const handlenameGgroupType = (producttypeid) => {
      const selectedType = groupapitypedata.find(
        (item) => item.producttypeid === producttypeid
      );
      setGroupTypeFields(selectedType ? selectedType.fields : []);
       setSelectedProductTypeId(producttypeid);
    };
    const renderDynamicFields = (fields) =>{
      return fields.map((field) => {
        <div className="card-header card-header-border-bottom">
                              <h2>Additional Inputs</h2>
                            </div>
if (field === "Color"){
  return(
    <div className="col-md-6 mb-25">
    <label className="form-label">Color</label>
    <ColorPaletteModal onSelectColor={handleSelectColor} />
    <div className="selected-color-box" style={{ backgroundColor: selectedColor, width: '50px', height: '50px' }}></div>
    <input
      type="text"
      className="form-control form-control-color"
      id="exampleColorInput1"
      value={colorname}
      onChange={(e) => setColorname(e.target.value)}
      required
    />
  </div>
  );
}
else if (field === "Size") {
  return (
    <div className="col-md-6 mb-25">
      <label className="form-label">Size</label>
      <select
        className="form-select"
        id="size"
        value={size}
        onChange={(e) => setSize(e.target.value)}
      >
        <option value="">Select Size</option>
        {sizeOptions.map((option) => (
          <option key={option.droupdownid} value={option.droupdownvalue}>
            {option.droupdownvalue}
          </option>
        ))}
      </select>
    </div>
  );
}
else if (field === "Ram"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">RAM</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={ram}
                                  onChange={(e) => setRam(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "Display"){
  return(
    <div className="col-md-6 mb-25">
    <label className="form-label">Display Size</label>
    <input
      type="text"
      className="form-control form-control-color"
      id="exampleColorInput1"
      value={display}
      onChange={(e) => setDisplaysize(e.target.value)}
      required
    />
  </div>
  );
}
else if (field === "os"){
  return(
    <div className="col-md-6 mb-25">
    <label className="form-label">Operating System</label>
    <input
      type="text"
      className="form-control"
      id="weight"
      value={os}
      onChange={(e) => setOS(e.target.value)}
      required
    />
  </div>
  );
}
else if (field === "Displayunit"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Display Unit</label>
                                <input
      type="text"
      className="form-control"
      id="weight"
      value={displayunit}
      onChange={(e) => setdisplayunit(e.target.value)}
      required
    />
                              </div>
  );
}
else if (field === "capacityunit"){
  <div className="col-md-6 mb-25">
                                <label className="form-label">Capacity Unit</label>
                                <input
      type="text"
      className="form-control"
      id="weight"
      value={capacityunit}
      onChange={(e) => setcapacityunit(e.target.value)}
      required
    />
                              </div>
}
else if (field === "Storage"){
  return(
    <div className="col-md-6 mb-25">
    <label className="form-label">Storage</label>
    <input
      type="text"
      className="form-control"
      id="weight"
      value={storage}
      onChange={(e) => setStorage(e.target.value)}
      required
    />
  </div> 
  );
}
else if (field === "Author") {
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Author</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={author}
                                  onChange={(e) => setAuthor(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "Unit"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Unit</label>
                                <Select
                                  style={{ width: "60%" }}
                                  onChange={handleUnitSelect}
                                  value={productunit}
                                  required
                                >
                                  <Select.Option value="Kg">Kg</Select.Option>
                                  <Select.Option value="G">g</Select.Option>
                                  <Select.Option value="L">L</Select.Option>
                                  <Select.Option value="ML">ml</Select.Option>
                                </Select>
                                {productunit && (
                                  <Button onClick={() => setUnit("")}>
                                    Clear
                                  </Button>
                                )}{" "}
                              </div>
  );
}
else if (field === "capacity"){
  return(
    <div className="col-md-6 mb-25">
    <label className="form-label">Capacity</label>
    <input
      type="text"
      className="form-control form-control-color"
      id="exampleColorInput1"
      value={capacity}
      onChange={(e) => setCapacity(e.target.value)}
      required
    />
  </div>
  );
}
else if (field === "starrating"){
  return(
    <div className="col-md-6 mb-25">
    <label className="form-label">Rating</label>
    <input
      type="text"
      className="form-control form-control-color"
      id="exampleColorInput1"
      value={starrating}
      onChange={(e) => setStarrating(e.target.value)}
      required
    />
  </div>
  );
}
else if (field === "type"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Type</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={type}
                                  onChange={(e) => setTypeac(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "Material"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Material</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={material}
                                  onChange={(e) => setMaterial(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "Packaging content"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Packaging content</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={packagecontent}
                                  onChange={(e) => setPackagecontent(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "Packaging weight"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Packaging weight</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={packageweight}
                                  onChange={(e) => setPackageweight(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "Packaging unit"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Packaging unit</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={packagingunit}
                                  onChange={(e) => setPackagingunit(e.target.value)}
                                  required
                                />
                              </div>
  );
}
else if (field === "fragile"){
  return(
    <div className="col-md-6 mb-25">
      <label className="form-label">BREAKABLE</label>
      <div>
        <label>
          <input
          type="radio"
          value="Yes"
          checked={fragile === true}
          onChange={() => setFragile(true)}
          />
          Yes
        </label>
        <label>
          <input
          type="radio"
          value="No"
          checked={fragile === false}
          onChange={() => setFragile(false)}
          />
          No
        </label>
      </div>
      </div>
  );
}
else if (field === "Weight"){
  return(
    <div className="col-md-6 mb-25">
                                <label className="form-label">Weight</label>
                                <input
                                  type="text"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={weight}
                                  onChange={(e) => setWeight(e.target.value)}
                                  required
                                />
                              </div>
  );
}
      }
      )
    }
  return (
    <div className="wrapper">
      <SideBar />
      <div className="ec-page-wrapper">
        <VendorHeader />
        <div className="ec-content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              <div>
                <h1>Add Product</h1>
                <p className="breadcrumbs">
                  <span>
                    <a href="index.html">Home</a>
                  </span>
                  <span>
                    <i className="mdi mdi-chevron-right"></i>
                  </span>
                  Product
                </p>
              </div>
              <div>
                <Link to="/ListProductforVendor" className="btn btn-primary">
                  {" "}
                  View All
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-header card-header-border-bottom">
                    {/* <h2>Add Product</h2> */}
                  </div>

                  <div className="card-body">
                    <div className="row ec-vendor-uploads">
                      <div className="col-lg-8">
                        <div className="ec-vendor-upload-detail">
                          <form onSubmit={onFinish}>
                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select Category
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  style={{ width: "60%" }}
                                  onChange={(value) => handleNameSelect(value)} // Pass a function reference
                                >
                                  {category.map((name) => (
                                    <Select.Option
                                      key={name.categoryid}
                                      value={name.categoryid}
                                    >
                                      {name.categoryname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select SubCategory
                                </label>
                                <Select
                                  style={{ width: "60%" }}
                                  onChange={(value) =>
                                    handleNameSubCategory(value)
                                  } // Pass a function reference
                                >
                                  {subcategoryList.map((name) => (
                                    <Select.Option
                                      key={name.subcategoryid}
                                      value={name.subcategoryid}
                                    >
                                      {name.subcategoryname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </div>
                              <div className="col-md-4">
                                <label className="form-label">
                                  Select SubSubCategory
                                </label>
                                {subsubcategoryList == null ? (
                                  <p>No Data</p>
                                ) : (
                                  <Select
                                    style={{ width: "60%" }}
                                    onChange={(value) =>
                                      handleNameSubSubCategory(value)
                                    }
                                  >
                                    {subsubcategoryList.map((name) => (
                                      <Select.Option
                                        key={name.subsubcategoryid}
                                        value={name.subsubcategoryid}
                                      >
                                        {name.subsubcategoryname}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">
                                  Product Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control slug-title"
                                  id="inputEmailc"
                                  value={producttags}
                                  onChange={(e) =>
                                    setProducttags(e.target.value)
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">
                                  Product Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control slug-title"
                                  id="inputEmail4"
                                  value={productname}
                                  onChange={(e) =>
                                    setProductname(e.target.value)
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label
                                for="slug"
                                className="col-12 col-form-label"
                              >
                                Slug
                              </label>
                              <div className="col-12">
                                <input
                                  id="slug"
                                  name="slug"
                                  className="form-control here set-slug"
                                  type="text"
                                  value={slug}
                                  onChange={(e) => setSlug(e.target.value)}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">Title (META TAG)</label>
                              <textarea
                              id="slug"
                              name="slug"
                              className="form-control here set-slug"
                              type="text"
                              value={sortdescription}
                              onChange={(e) => setSortdescription(e.target.value)}
                              autoComplete="off"
                              style={{resize: 'none'}}
                              ></textarea>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label">
                                Full Description
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={fulldetails}
                                onReady={(editor) => {
                                  // You can store the "editor" and use when it is needed.
                                  console.log(
                                    "Editor is ready to use!",
                                    editor
                                  );
                                }}
                                onChange={(event, editor) => {
                                  const newSortDescription = editor.getData();
                                  setFulldetails(newSortDescription);
                                }}
                                onBlur={(event, editor) => {
                                  console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                  console.log("Focus.", editor);
                                }}
                              />
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <label className="form-label">MRP Price</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="price1"
                                  value={mrpprice}
                                  onChange={(e) => setMrpprice(e.target.value)}
                                />
                              </div>
                              <div className="col-md-4">
  <label className="form-label">Selling Price</label>
  <input
    type="number"
    className="form-control"
    id="price2"
    value={sellingprice}
    onChange={(e) => handleMrpPrice(e.target.value)}
  />
  {!isSellingPriceValid && (
    <div className="error-message">{sellingPriceErrorMessage}</div>
  )}
</div>

                            </div>
                            <div className="col-md-12">
                              <label className="form-label">Quantity</label>
                              <input
                                type="number"
                                className="form-control"
                                id="quantity1"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                              />
                            </div>
                            <div className="col-md-12">
                              <label className="form-label" name="previewimage">
                              Main Image (Do not Repeat Main Image)
                              </label>
                              <Form.Item name="previewimage">
                                <Upload
                                  customRequest={handleFileUploadforpreview} // Use the customRequest prop for custom upload handling
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                    style={{ top: "0px", borderRadius: "0px" }}
                                  >
                                    Click to Upload
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="col-md-12">
                              <label className="form-label" name="uploadfile">
                                Other Image
                              </label>
                              <Form.Item name="uploadfile">
                                <Upload
                                  customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
                                  fileList={fileList}
                                  onChange={({ fileList }) =>
                                    setFileList(fileList)
                                  }
                                >
                                  <Button
                                    icon={<UploadOutlined />}
                                    style={{ top: "0px", borderRadius: "0px" }}
                                  >
                                    Click to Upload
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </div>
                          
                            <div className="col-md-12">
                              <label className="form-label">
                                Youtube Link
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={youtube}
                                onChange={(e) =>
                                  setYoutube(e.target.value)
                                }
                              />
                            </div> 
                            
                            {groupTypeFields.length > 0 && (
        <div className="row mt-3">
          {renderDynamicFields(groupTypeFields)}
        </div>
      )}
                            {/* <div className="row">
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Color</label>
                                <input
                                  type="color"
                                  className="form-control form-control-color"
                                  id="exampleColorInput1"
                                  value={color}
                                  onChange={(e) => setColor(e.target.value)}
                                />
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">
                                  Colour Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={colorname}
                                  onChange={(e) => setColorname(e.target.value)}
                                />
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Weight</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={weight}
                                  onChange={(e) => setWeight(e.target.value)}
                                />
                              </div>
                              <div className="col-md-3 mb-25">
                                <label className="form-label">Unit</label>
                                <Select
                                  style={{ width: "60%" }}
                                  onChange={handleUnitSelect}
                                  value={productunit}
                                >
                                  <Select.Option value="Kg">Kg</Select.Option>
                                  <Select.Option value="G">g</Select.Option>
                                  <Select.Option value="L">L</Select.Option>
                                  <Select.Option value="ML">ml</Select.Option>
                                </Select>
                                {productunit && (
                                  <Button onClick={() => setUnit("")}>
                                    Clear
                                  </Button>
                                )}{" "}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-25">
                                <label className="form-label">Size</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={size}
                                  onChange={(e) => setSize(e.target.value)}
                                />
                              </div>
                              <div className="col-md-6 mb-25">
                                <label className="form-label">Storage</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  value={storage}
                                  onChange={(e) => setStorage(e.target.value)}
                                />
                              </div>
                            </div> */}
                            <div className="col-md-12">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductAddforVendor;
