import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSpring, animated } from 'react-spring';

const PaymentSuccess = () => {
    const token = sessionStorage.getItem('tokenforseller');
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);
    const status = urlParams.get("redirect_status");
    const [redirectStatus, setRedirectStatus] = useState('');
    const paymentforSellerEdit = JSON.parse(sessionStorage.getItem('paymentforSellerEdit'));

    const successAnimation = useSpring({
        opacity: redirectStatus === 'succeeded' ? 1 : 0,
        transform: redirectStatus === 'succeeded' ? 'translateY(0)' : 'translateY(-20px)',
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      });
    
      const failureAnimation = useSpring({
        opacity: redirectStatus !== 'succeeded' ? 1 : 0,
        transform: redirectStatus !== 'succeeded' ? 'translateY(0)' : 'translateY(-20px)',
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      });
  const navigate = useNavigate();

  useEffect(() => {
    if(status!=null){
        if (status !== 'succeeded') {
            // Redirect to the Stage3 page if payment failed
            setTimeout(() => {
                navigate('/Stage3');
              }, 5000);
          }else{ 
            
  try {
    // Find the selected plan by sellerplanid from the stored planOptions

    const paymentdetails = window.location.href;
    paymentforSellerEdit.paymentdetails =paymentdetails;
    // TODO: Replace with actual API call
    const endpoint = paymentforSellerEdit.repayment
  ? "https://api.starmallonline.com/api/v1/seller/repayment"
  : "https://api.starmallonline.com/api/v1/seller/edit";
    const response = fetch(endpoint, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      },
      body: JSON.stringify(paymentforSellerEdit),
    });

    if (response.status === 401 || response.status === 404) {
      // Redirect to the login page for 401 or 404 errors
      // navigate('/login');
      return; // Exit early to prevent further logic execution
    }

    if (response.ok) {
      const data = response.json();
      console.log("POST request successful", data);
      // window.location.reload(); // Refresh the page
      // Optionally, you can update your component state or perform other actions here
      sessionStorage.removeItemgetItem('paymentforSellerEdit')
    } else {
      console.error("POST request failed");
    }
  } catch (error) {
    console.error("Error posting data:", error);
  }
            setTimeout(() => {
            navigate('/AdminLogin');
          }, 5000);
          }
    }



    // Get the current URL only once when the component mounts
    
    // Parse the URL parameters
    
    // Get the value of the redirect_status parameter
    const paystatus = status === 'succeeded' ? true : false;
    const payment_intent = urlParams.get("payment_intent");
    const payment_intent_client_secret = urlParams.get("payment_intent_client_secret");
    
    // Update the state with the status
    setRedirectStatus(status);
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  const { payment_intent, payment_intent_client_secret } = useParams();
  
  return (
    <div>
      
      <animated.div style={successAnimation}>
        <h1>Payment Status</h1>
        <h3>Payment Success</h3>
        <h5>please wait to redirect to login ....</h5>

      </animated.div>

      <animated.div style={failureAnimation}>
         <h1>Payment Status</h1>
        <h3>Payment Failed. Please try again</h3>
        <h5>please wait to redirect ....</h5>   
      </animated.div>
      
      {/* You can use payment_intent and payment_intent_client_secret as needed */}
    </div>
  );
};

export default PaymentSuccess;
