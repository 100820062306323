import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom"; 
import { Link } from "react-router-dom";
import "./style.scss";
import "./riot_admin.scss";
import SideBar from "./sidebar";
import { Input, Button, Modal, Table, Checkbox , Tag,notification} from "antd";
import Header from "./Header";
import EditProductModal from './EditProduct';
import RestockModal from './RestockModal';
import noImage from "../src/images/noimage.jpeg";
const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
  return (
    <Modal
      visible={visible}
      title="Confirm Deletion"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="delete" type="primary" onClick={onConfirm}>
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this product?</p>
    </Modal>
  );
};

const DuplicateProduct = () => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoryList, setCategoriesList] = useState([]);
    const [restockModalVisible, setRestockModalVisible] = useState(false);
	  const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedProductids, setSelectedProductids] = useState([]);
   const [selectedImage, setSelectedImage] = useState(null);
   const [categoryName, setCategoryName] = useState('');
   const [subCategoryName, setSubCategoryName] = useState('');
   const [subsubcategoryName, setSubsubcategoryName] = useState('');
   const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };
    const handleCheckboxChange = (productid) => {
      // Toggle the value in sessionStorage
      const selectedProductids = JSON.parse(sessionStorage.getItem('availabledProductids')) || [];
      const index = selectedProductids.indexOf(productid);
  
      if (index !== -1) {
        selectedProductids.splice(index, 1);
      } else {
        selectedProductids.push(productid);
      }
  
      sessionStorage.setItem('availabledProductids', JSON.stringify(selectedProductids));
      setSelectedProductids(selectedProductids);
    };
    useEffect(() => {
      // Fetch data when the component mounts
      fetchData();
      
    }, []);
  
    const fetchData = async () => {
      try {
        setLoading(true);
  
        // Get productgroupnumber and currency from local storage
        const productgroupnumber = localStorage.getItem("duplicatedProductGroupNumber");
        const currency = localStorage.getItem("selectedCurrency");
  
        // Make a POST request to the API
        const response = await axios.put(
          "https://api.starmallonline.com/api/v1/product/getbyproductgroupnumberforadmin",
          {
            productgroupnumber,
            currency,
          },
          {
            headers: {
                "Content-Type": "application/json",
            },
          }
        );
  
        setData(response.data);
        if(response.data && response.data.length > 0 ) {
          const categoryname = response.data[0].categoryname;
          const subcategoryname = response.data[0].subcategoryname;
          const subsubcategoryname = response.data[0].subsubcategoryname;
          setCategoryName(categoryname);
          setSubCategoryName(subcategoryname);
          setSubsubcategoryName(subsubcategoryname);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    const totalProductCount = data.length;
    const handleDuplicateClick = async () => {
      try {
        // Assuming the 0th index is not empty
        // const firstProduct = data[0];
    
        // Make a POST request to the "add" endpoint with the product ID
        const response = await axios.post(
          "https://api.starmallonline.com/api/v1/product/dupilicate",
          {
            productidarray: selectedProductids, // Adjust the key based on the actual product ID key in your API response
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add the authorization header if required
            },
          }
        );
        if(response.data.status =="Success"){
       
        }
    
        // Check the response and handle success or failure
        if (response.status === 200) {
          // Handle success, e.g., show a success message or redirect to another page
          console.log("Product duplicated successfully");
          notification.success({
            message: response.data.description,
            duration: 3, // Duration in seconds
          });
          sessionStorage.removeItem('availabledProductids');
          setSelectedProductids([]);
          fetchData();
          // You may want to redirect to another page or perform other actions here
        } else {
          // Handle failure, e.g., show an error message
          console.error("Failed to duplicate product:", response.data);
          notification.error({
            message: response.data.description,
            duration: 3, // Duration in seconds
          });
        }
       
      } catch (error) {
        console.error("Error duplicating product:", error);
      }
    };
    const handleEditCategory = (product) => {
      const fileids = product.fileModel!=null ? product.fileModel.map(file => file.fileid) : [];
      const previewfileids = product.previewimg != null ? product.previewimg.fileid : "";

      // Store fileids in sessionStorage
      sessionStorage.setItem('fileids', fileids!==null ? JSON.stringify(fileids) : JSON.stringify([]));
      sessionStorage.setItem('previewfileids', previewfileids);
      localStorage.setItem('selectedProductid', product.productid);
      localStorage.setItem('selectedQuantity', product.quantity);
      localStorage.setItem('selectedProductGroupNumber', product.productgroupnumber);
      localStorage.setItem('selectedCurrency', product.currency);
      setSelectedProduct(product);
      setShowEditModal(true);
      };
      const handleCloseEditModal = () => {
        setSelectedProduct(null);
        setShowEditModal(false);
        };
        const handleSaveEdit = async (editedData) => {
          try {
            setLoading(true);
            editedData.categoryid = selectedCategory.categoryid;
            editedData.productid = selectedProduct.productid;
            editedData.subcategoryid = selectedProduct.subcategoryid;
            editedData.categoryname = selectedCategory.categoryname;
            delete editedData.fileModel;
            // Make an API request to update the subcategory data
            const response = await axios.post(
            `https://api.starmallonline.com/api/v1/product/edit`,
            editedData,
            {
              headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'application/json',
              },
            }
            );
        
            if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/AdminLogin');
            return;
            }
            notification.success({
              message: response.data.description,
              duration: 3, // Duration in seconds
            });
            fetchData();
            if (response.status === 200) {
            console.log('Subcategory updated successfully');
            // Optionally, you can update your component state or perform other actions here
            // window.location.reload();
            } else {
            console.error('PUT request failed');
            }
          } catch (error) {
            console.error('Error updating data:', error);
          } finally {
            setLoading(false);
            handleCloseEditModal(); // Close the modal whether the request succeeds or fails
          }
          };
          useEffect(() => {
            const fetchCategoryNames = async () => {
              try {
                const response = await axios.put("https://api.starmallonline.com/api/v1/category/get",{},{
                  headers: {
                  'Content-Type': 'application/json',
                  },
                });
                if (response.status === 401 || response.status === 404) {
                  // Redirect to the login page for 401 or 404 errors
                  navigate('/AdminLogin');
                  return; // Exit early to prevent further logic execution
                }
                setCategoriesList(response.data);
              } catch (error) {
                console.error("Error fetching names:", error);
              }
            };
        
            fetchCategoryNames();
          
        }, []);
        const handleRestock = (row) => {
          const productId = row.productid;
          localStorage.setItem('selectedProductidForRestockforDuplicate', productId);
          setRestockModalVisible(true);
        };
        const handleDeleteCategoryConfirmed = async () => {
          try {
            setLoading(true);
        
            // Make an API request to delete the category
            const response = await axios.post(
            `https://api.starmallonline.com/api/v1/product/delete`,
            {
              categoryid: selectedCategory,
            },
            {
              headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
              },
            }
            );
        
            if (response.status === 200) {
              notification.success({
                message: 'Product deleted successfully',
                duration: 3, // Duration in seconds
              });
            // Refresh the page or update the category list
            fetchData();
            } else {
            console.error('Failed to delete category');
            }
          } catch (error) {
            console.error('Error deleting category:', error);
          } finally {
            setLoading(false);
            setShowDeleteModal(false);
          }
          };
        const handleDeleteCategory = (productId) => {
          setSelectedCategory(productId);
          setShowDeleteModal(true);
          };
    const columns = [
      {
        title: '',
        dataIndex: 'checkbox',
        render: (_, record) => (
          <Checkbox
          checked={selectedProductids.includes(record.productid)}
            onClick={(e) => handleCheckboxChange(record.productid)}
          />
        ),
      },
        {
          title: 'Product Name',
          dataIndex: 'productname',
          key: 'productname',
        },
        {
          title: 'MRP Price',
          dataIndex: 'mrpprice',
          key: 'mrpprice',
        },
        {
          title: 'Selling Price',
          dataIndex: 'sellingprice',
          key: 'sellingprice',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
        },
        {
          title: 'Image',
          dataIndex: 'previewimg.file',
          render: (text,record) =>
          record.previewimg && record.previewimg.file ? (
          <img src={record.previewimg.file} alt="Product Preview" width={"100px"} height={"100px"} onClick={() => handleImageClick(record.previewimg.file)}/>
          ) : (
            <img src={noImage} width={"100px"} height={"100px"}/>
          )
        },
        {
          title: 'Colour',
          dataIndex: 'color',
          key: 'color',
          render: (text, record) => (
<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  {record.color ? (
    <>
      <Tag color={record.color} style={{ width: "20px", height: "20px", marginBottom: "5px" }} />
      <span>{record.color}</span>
    </>
  ) : (
    <span>Not Selected</span>
  )}
</div>

          ),
        },
        {
          title: 'Restock',
          key: false,
          render: (text, row) => (
            <Button type="primary" onClick={() => handleRestock(row)}>
              Restock
            </Button>
          ),
         },
        {
          title: 'Action',
          key: 'action',
          render: (text, row) => (
            <div className="btn-group">
              <a
                href="#"
                className="btn btn-outline-success" style={{width:"100px"}}
                onClick={(e) => {
                  e.preventDefault();
                  handleEditCategory(row); // Pass the entire row data to the edit function
                }}
              >
                Edit
              </a>
              <a
            href="#"
            className="btn btn-outline-danger" style={{width:"100px"}}
            onClick={(e) => {
              e.preventDefault();
              // Add your delete logic here, you can access row data with `row`
              // For example, you can call a function to handle delete passing row.id
              handleDeleteCategory(row.productid);
            }}
          >
            Delete
          </a>
            </div>
          ),
        }
        
        
      ];
    return(
        <div className="wrapper">
        <SideBar />
        <div className="ec-page-wrapper">
        <Header />
        <div className="ec-content-wrapper">
          <div className="content" >
            <div className="container-fluid admin-titles">
              <div className="row">
                <div className="col-md-8"><div>
                <h1>Product</h1>
                <p className="breadcrumbs">
                  <span>
                    <a href="index">Home</a>
                  </span>
                  <span>
                    <i className="mdi mdi-chevron-right"></i>
                  </span>
                  Product
                </p>
              </div></div>
                <div className="col-md-2 text-end">  <div>
                <button className="btn btn-primary" onClick={handleDuplicateClick}>
                  Duplicate
                </button>
              </div></div>
                <div className="col-md-2 text-end">  <div><h4>Total Counts: {totalProductCount}</h4></div></div>
              </div>
            </div>
          <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
              
            
            
            </div>
            {loading  ? (
  <div className="container-fluied">
    <div className="row">
      <div className="muti-col-loader"></div>
    </div>
  </div>
) : (
        <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-body">
                    <div className="row">
                      {categoryName && subCategoryName ? (
                        <span className="">
                          {categoryName} {" > " + subCategoryName}
                          {subsubcategoryName && " > " + subsubcategoryName}
                        </span>
                      ) : (
                        <p className="">{categoryName}</p>
                      )}
                    </div>
                  <Table
                      columns={columns}
                      dataSource={data}
                      pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '25', '50', '100'],
                      }}
                      scroll={{ x: 'max-content' }}
                    />
                  </div>
                </div>
              </div>
            </div>
)}
            </div>
            </div>
            {selectedProduct && (
  <EditProductModal
  show={showEditModal}
  onHide={handleCloseEditModal}
  onSave={(editedData) => handleSaveEdit(editedData, fetchData)}
  categoryList={categoryList}
  category={selectedCategory}
  product={selectedProduct}
  productid={localStorage.getItem('selectedProductid')}
  quantity={localStorage.getItem('selectedQuantity')}
  />
)}
{selectedCategory && (
            <DeleteCategoryModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteCategoryConfirmed}
            />
          )}
 <RestockModal
          visible={restockModalVisible}
          onCancel={() => setRestockModalVisible(false)}
          onAddRestock={(quantity) => {
            console.log('Add Restock button clicked with value:', quantity);
            setRestockModalVisible(false);
          }}
          onRemoveRestock={() => {
            console.log('Remove Restock button clicked for productid:');
            setRestockModalVisible(false);
          }}
          productid={localStorage.getItem('selectedProductidForRestockforDuplicate')}
          fetchData={fetchData}
        />
         <Modal
        visible={!!selectedImage}
        onCancel={handleCloseImageModal}
        footer={null}
        width={1200}
      >
        <img src={selectedImage} alt="Selected Banner" style={{ width: '100%' }} />
      </Modal>
        </div>
        </div>
    )
}
export default DuplicateProduct;