import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../src/style.scss';
import '../../../../src/riot_admin.scss';
import SideBar from "../../../vendor/sidebar/index";
import VendorHeader from "../../../../src/vendor/header";
import ReactQuill from 'react-quill';
import axios from "axios";
import { Link } from 'react-router-dom';


  const AgreedProcessPayment = () => {
	const navigate = useNavigate();
	const [agreedProcessPayment, setTermsconditions] = useState('');
  const [commonDetailsId, setCommonDetailsId] = useState(''); // Assuming you have a way to obtain this ID
  const [commonDetailsField, setCommonDetailsField] = useState('agreedProcessPay'); // Adjust as needed
    // Function to fetch common details
  const fetchCommonDetails = async () => {
    try {
		const payload={
			commondetailsfield: 'agreedProcessPay',
		}
      const response = await axios.put("https://api.starmallonline.com/api/v1/commondetails/getcommondetails",payload,{
        
      });
      if (response.status === 200) {
        // Successfully fetched common details
        const commonDetails = response.data;
        setTermsconditions(commonDetails.commondetails);
        // Do something with commonDetails, such as updating your component's state
        console.log("Common Details:", commonDetails);
        
      } else {
        console.error("Failed to fetch common details");
      }
    } catch (error) {
      console.error("Error fetching common details:", error);
    }
  };

  // useEffect to fetch common details when the component mounts
  useEffect(() => {
    fetchCommonDetails();
  }, []);


	const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
	  });
	  // Function to edit common details
  const editCommonDetails = async () => {
    try {
      const payload = {
        commondetailsid: commonDetailsId, // Pass the common details ID you want to edit
        commondetails: agreedProcessPayment,
        commondetailsfield: commonDetailsField,
      };

      const response = await axios.post("https://api.starmallonline.com/api/v1/commondetails/editcommondetails", payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
        },
      });

      if (response.status === 200) {
        // Successfully edited common details
        const editedCommonDetails = response.data;
        // Do something with the editedCommonDetails, if needed
        console.log("Edited Common Details:", editedCommonDetails);
      } else {
        console.error("Failed to edit common details");
      }
    } catch (error) {
      console.error("Error editing common details:", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    editCommonDetails();
  };
	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>


			{/* <!-- Header --> */}
			<VendorHeader/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Agreed Process to Payment</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Agreed Process to Payment</p>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<form
										>
                                            <div className="form-group row">
												<label className="col-12 col-form-label">Description</label> 
												<div className="col-12">
                                                <ReactQuill
													value={agreedProcessPayment}
                          onChange={(value) => setTermsconditions(value)}
													/>

												</div>
											</div> 

										</form>

									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div> 


	</div>
  );
}

export default AgreedProcessPayment;
