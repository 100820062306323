import React from 'react';
import './header.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../images/shopeasey.svg';
const LoginHeader = () => {
	const location = useLocation();
	const showSellerRegistration = location.pathname !== '/seller';
  return (
    <header className="header-middle" >
      
	  <div className='container d-flex justify-content-between'>
		<div className='logo'>
                {/* <Logo  alt="logo" width="160"   className="top-logo " /> */}
				</div>
		<div className='login-btn d-flex align-items-center'>
			{showSellerRegistration ? (
		<h3>StarMall</h3>
		) : (
			<Link to="/AdminLogin">Login</Link>
		)}
		</div>
			

	  </div>

    </header>
  );
};

export default LoginHeader;
