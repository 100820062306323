import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './riot_admin.scss';
import SideBar from './sidebar';
import {Select, Form, Upload, Button ,DatePicker,Modal } from "antd";
import Header from './Header';
import EditCategoryModal from './EditCategoryModal';
import { UploadOutlined } from '@ant-design/icons';

const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];


  const Offer = () => {
	const token = localStorage.getItem('token');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	  const [categories, setCategories] = useState([]);
	  const [loading, setLoading] = useState(true);
	  const navigate = useNavigate();
	  
	  const [title, setTitle] =useState('');
	  const [description, setDescription] =useState('');
	  const [fromdatetime, setFromDate] =useState('');
	  const [todatetime, setToDate] =useState('');
	  const [data, setData] = useState([]);
      const [fileList, setFileList] = useState([]); // To store uploaded files
    const [file, setFile] =useState([]); 

	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	  const [showDeleteModal, setShowDeleteModal] = useState(false);
	  const [selectedOfferForDeletion, setSelectedOfferForDeletion] = useState(null);
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	
	  // Function to close the edit modal
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  const handleSaveEdit = (editedData) => {
		// Handle the save logic here and update the categories array with the edited data
		// You can use the editedData to make an API call to save the changes
		console.log('Edited data:', editedData);
		// After saving, you can update the categories array and close the modal
		// For example, update the categories array and close the modal as follows:
		// setCategories((prevCategories) => {
		//   const updatedCategories = prevCategories.map((category) => {
		//     if (category.id === selectedCategory.id) {
		//       return { ...category, ...editedData };
		//     }
		//     return category;
		//   });
		//   return updatedCategories;
		// });
		handleCloseEditModal();
	  };
	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("https://api.starmallonline.com/api/v1/offer/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

	const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append("file", file);
        formData.append("title", title);
        formData.append("description", description);
		formData.append("fromdatetime", fromdatetime);
		formData.append("todatetime", todatetime);
	  
		try {
		  setLoading(true);
		  // TODO: Replace with actual API call
		  const response = await fetch("https://api.starmallonline.com/api/v1/offer/add", {
			method: "POST",
            body:
                formData,
			headers: {
			  'Authorization': 'Bearer ' + token,
			//   'Content-Type': 'application/json',
			},
		  });
	  
		//   if (response.status === 401 || response.status === 404) {
		// 	// Redirect to the login page for 401 or 404 errors
		// 	navigate('/AdminLogin');
		// 	return; // Exit early to prevent further logic execution
		//   }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			setLoading(false);
			// window.location.reload(); // Refresh the page
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  }; 
	  const handleFileUpload = (options) => {
        const { file } = options; // Extract the selected file from options
        setFile(file); // Store the selected file in the state
      };


	const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  
	//   const handleDeleteCategory = async (offerid) => {
	// 	try {
	// 	  setLoading(true);
		
	// 	  // Make an API request to delete the category
	// 	  const response = await axios.post(
	// 	  `https://api.starmallonline.com/api/v1/offer/delete`,
	// 	  {
	// 		offerid: offerid, // Pass the categoryId as a payload
	// 	  },
	// 	  {
	// 		headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 		},
	// 	  }
	// 	  );
	// 	  if (response.status === 200) {
	// 	  // Refresh the page
	// 	  window.location.reload();
	// 	  } else {
	// 	  console.error('Failed to delete Common Info');
	// 	  }
		
	// 	  // Rest of the code remains the same
	// 	  // ...
	// 	} catch (error) {
	// 	  console.error('Error deleting Common Info:', error);
	// 	  setLoading(false);
	// 	}
	// 	};
		const handleDeleteCategory = async (offerid) => {
			try {
			  setLoading(true);
		  
			  // Set the selected offer for deletion
			  const selectedOffer = data.find((offer) => offer.offerid === offerid);
			  setSelectedOfferForDeletion(selectedOffer);
		  
			  // Show the delete confirmation modal
			  setShowDeleteModal(true);
			} catch (error) {
			  console.error('Error preparing delete:', error);
			  setLoading(false);
			}
		  };
		  const handleDeleteOfferConfirmed = async () => {
			try {
			  // Make an API request to delete the offer
			  const response = await axios.post(
				`https://api.starmallonline.com/api/v1/offer/delete`,
				{
				  offerid: selectedOfferForDeletion.offerid,
				},
				{
				  headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				  },
				}
			  );
		
			  if (response.status === 200) {
				// Refresh the page or update the offer list
				fetchData();
			  } else {
				console.error('Failed to delete offer');
			  }
			} catch (error) {
			  console.error('Error deleting offer:', error);
			} finally {
			  // Clear the selected offer and hide the modal
			  setSelectedOfferForDeletion(null);
			  setShowDeleteModal(false);
			}
		  };

	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		<div class="ec-page-wrapper">
			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" >
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Offers</h1>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Add New Offer</h4>

										<form
										onSubmit={onFinish}
										>

                                                <div className="col-md-12">
													<label className="form-label" name="uploadfile">Upload Images</label>
													<Form.Item
															name="uploadfile"
														>
														<Upload
															customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
															
															>
															<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
														</Upload>
													</Form.Item> 
													{/* <Upload
														customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
														fileList={fileList}
														onChange={({ fileList }) => setFileList(fileList)}
														>
											<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
										</Upload> */}
												</div>
                                                <div className="col-md-12">
                                                <label className="form-label" name="title">Title</label>
                                                <input id="title" name="title" className="form-control here set-slug" type="text" value={title} onChange={(e) => setTitle(e.target.value)}/>
                                                </div>
                                                <div className="col-md-12">
														<label for="description" className="col-12 col-form-label">Description</label> 
														<div className="col-12">
															<input id="description" name="description" className="form-control here set-slug" type="text" value={description} onChange={(e) => setDescription(e.target.value)}/>
														</div>
													</div>
													<div className="col-md-12">
														<label className="form-label" name="fromdate">From Date</label>
														<DatePicker
															value={fromdatetime}
															onChange={(date) => setFromDate(date)}
															style={{ width: '100%' }}
														/>
														</div>
														<div className="col-md-12">
														<label className="form-label" name="todate">To Date</label>
														<DatePicker
															value={todatetime}
															onChange={(date) => setToDate(date)}
															style={{ width: '100%' }}
														/>
														</div>


											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>Image</th>
													<th>Title</th>
													<th>Description</th>
													<th>Offer Period Start</th>
													<th>Offer Period End</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((category) => (
                          <tr key={category.orderid}>
                            <td><img src={category.file} alt="category.offerid" width="280" height="215"/></td>
                            <td>{category.title}</td>
                            <td>{category.description}</td>
							<td>{category.offerperiodstart}</td>
							<td>{category.offerperiodend}</td>
                            <td>
							<div className="btn-group">
    {/* <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleEditCategory(category);
      }}
    >
      Edit
    </a> */}
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleDeleteCategory(category.offerid);
      }}
    >
      Delete
    </a> 
  </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 

			 <Modal
        visible={showDeleteModal}
        title="Confirm Deletion"
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDeleteOfferConfirmed}
        okText="Delete"
      >
        <p>Are you sure you want to delete this offer?</p>
      </Modal>


			{selectedCategory && (
  <EditCategoryModal
    category={selectedCategory}
    show={showEditModal} // Pass the value of showEditModal as isModalVisible
    onHide={handleCloseEditModal}
    onSave={handleSaveEdit}
  />
)}

</div>
	</div>
  );
}

export default Offer;
