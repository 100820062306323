import React from "react";
import '../style.scss';
import '../riot_admin.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from "../../src/images/shopeasey.svg";

const AfterRegister = () => {
  return (
    <div className="wrapper">
         <div className="ec-page-wrapper">
         <div className="ec-content-wrapper">
         <div className="content" style={{ marginTop: '50px' }}>
         <div className="row justify-content-center align-items-center">
         <div className="col-xl-4 col-lg-12">
         <div className="ec-cat-list card card-default mb-24px">
         <div className="card-body">
         <div className="ec-cat-form">
         <div className="test">
          {/* <Logo alt="Logo" width='100%'/> */}
            <h4 className="log_cap">Sign in to continue to Login.</h4>
          </div>
      <h4>Registration Initiated. Please login to continue...</h4>
      <div className="row">
                          <div className="col-12">
                          <Link to="/AdminLogin">
                            <button name="submit" type="submit" className="btn btn-primary">
                          Continue
                            </button>
                            </Link>
                          </div>
                        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  );
}

export default AfterRegister;
