// Header.js
import React, { useState, useEffect } from 'react';
import './style.scss';
import './riot_admin.scss';
import {  Link } from 'react-router-dom';
import Logo  from "../src/images/shopeasey.svg";


function Header() {
	
	const handleLogout = () => {
		// Remove tokens from storage
		localStorage.removeItem('token');
		sessionStorage.removeItem('tokenforseller');
		localStorage.clear();
  sessionStorage.clear();
	
	  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	  const [logoUrl, setLogoUrl] = useState('');
	  useEffect(() => {
		// Retrieve the logo URL from session storage
		const logoUrlFromStorage = sessionStorage.getItem("logoUrl");
		if (logoUrlFromStorage) {
		  setLogoUrl(logoUrlFromStorage);
		}
	  }, []);
	//   useEffect(() => {
	// 	const fetchLogo = async () => {
	// 	  try {
	// 		const response = await fetch('https://api.starmallonline.com/api/v1/logo/getall',{
	// 		  method: 'PUT',
	// 		  headers: {
	// 			'Content-Type': 'application/json',
				
	// 		  },
	// 		});
	// 		if (response.ok) {
	// 		  const data = await response.json();
	// 		  setLogoUrl(data.logo); // Update the logo URL in the state
	// 		} else {
	// 		  console.error('Failed to fetch logo');
	// 		}
	// 	  } catch (error) {
	// 		console.error('Error fetching logo:', error);
	// 	  }
	// 	};
	
	// 	fetchLogo();
	//   }, []); 
// 	const [classesAdded, setClassesAdded] = useState(false);

//   const handleClick = () => {
//     const rootElement = document.getElementById('root');
//     if (rootElement) {
//       if (classesAdded) {
//         rootElement.classList.remove('ec-header-fixed', 'ec-sidebar-fixed', 'sidebar-minified');
//       } else {
//         rootElement.classList.add('ec-header-fixed', 'ec-sidebar-fixed', 'sidebar-minified');
//       }
//       setClassesAdded(!classesAdded);
//     }
//   };
const [isMinified, setIsMinified] = useState(false);

const handleClick = () => {
  const rootElement = document.getElementById('root');
  if (rootElement) {
	// Remove both classes to ensure only one of them is applied
	rootElement.classList.remove('sidebar-minified', 'sidebar-minified-out');
	
	// Add the desired class based on the current state
	if (isMinified) {
	  rootElement.classList.add('sidebar-minified-out');
	} else {
	  rootElement.classList.add('sidebar-minified');
	}
	
	// Toggle the state
	setIsMinified(!isMinified);
  }
};
const handleNotification = async () => {
	try {
	  const apiUrl = `https://api.starmallonline.com/api/v1/notification/recent`;
	  const requestBody = {
		sellerid: 1,
	  };
  
	  const response = await fetch(apiUrl, {
		method: "POST",
		headers: {
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${localStorage.getItem('token')}`,
		},
		body: JSON.stringify(requestBody),
	  });
  
	  if (!response.ok) {
		console.error('Error fetching data:', response.statusText);
		return;
	  }
  
	  const data = await response.json();
	  setNotifications(data);
	  // Show the modal
	  setModalVisible(true);
	} catch (error) {
	  console.error('Error fetching data:', error);
	}
  };
  const handleCloseNotifications = () => {
	// Hide the notifications section by updating state
	setShowNotifications(false);
  };
  return (
	 
    <header className="ec-main-header" id="header">
				<nav className="navbar navbar-static-top navbar-expand-lg">
				<button id="sidebar-toggler" class="sidebar-toggle" onClick={handleClick}></button>
					{/* <div className='navbar-logo '>
					<img
              id="sidebar-toggler"
              className="sidebar-toggle" src={Logo}
			  style={{width:"120px"}}
            ></img></div> */}
					<div className="search-form d-lg-inline-block">
						{/* <div className="input-group">
							<input type="text" name="query" id="search-input" className="form-control search"
								placeholder="search.." autofocus autocomplete="off" />
							<button type="button" name="search" id="search-btn" className="btn btn-flat">
								<i className="mdi mdi-magnify"></i>
							</button>
						</div> */}
						{/* <div id="search-results-container">
							<ul id="search-results"></ul>
						</div> */}
					</div>

					{/* <!-- navbar right --> */}
					<div className="navbar-right">
						<ul className="nav navbar-nav">
							{/* <!-- User Account --> */}
							<li className={`dropdown user-menu ${isDropdownOpen ? 'open' : ''}`}>
								<button className="dropdown-toggle nav-link ec-drop" data-bs-toggle="dropdown"
                 onClick={toggleDropdown}
									aria-expanded="false">
									<img src="assets/img/user/user.png" className="user-image" alt="" />Admin
								</button>
								<ul className={`dropdown-menu dropdown-menu-right ec-dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
									{/* <!-- User image --> */}
									<li className="dropdown-header">
										{/* <img src="assets/img/user/user.png" className="img-circle" alt="User Image" /> */}
										<div className="d-inline-block">
											Admin <small className="pt-1"></small>
										</div>
									</li>
									<li>
										<Link to="/main-company">
											<i className="mdi mdi-account"></i>Profile
										</Link>
									</li>
									{/* <li>
										<a href="#">
											<i className="mdi mdi-email"></i> Message
										</a>
									</li>
									<li>
										<a href="#"> <i className="mdi mdi-diamond-stone"></i> Projects </a>
									</li>
									<li className="right-sidebar-in">
										<a href="javascript:0"> <i className="mdi mdi-settings-outline"></i> Setting </a>
									</li> */}
									<li className="dropdown-footer">
										<Link to="/AdminLogin" onClick={handleLogout}> <i className="mdi mdi-logout"></i> Log Out </Link>
									</li>
								</ul>
							</li>
							<li className="dropdown notifications-menu custom-dropdown">
								<button className="dropdown-toggle notify-toggler custom-dropdown-toggler" onMouseEnter={handleNotification} >
									<i className="mdi mdi-bell-outline" onClick={() => setShowNotifications(true)}></i>
								</button>
								{showNotifications && (
								<div className="card card-default dropdown-notify dropdown-notify-open  dropdown-menu-right mb-0">
									<div className="card-header card-header-border-bottom px-3">
										<h2>Notifications</h2>
										<button className="close-btn" style={{marginLeft:"50%"}} onClick={handleCloseNotifications}>
            X
          </button>
									</div>

									<div className="card-body px-0 py-0">
										<ul className="nav nav-tabs nav-style-border p-0 justify-content-between" id="myTab"
											role="tablist">
											<li className="nav-item mx-3 my-0 py-0">
												<a href="#" className="nav-link active pb-3" id="home2-tab"
													data-bs-toggle="tab" data-bs-target="#home2" role="tab"
													aria-controls="home2" aria-selected="true">All (10)</a>
											</li>
										</ul>

										<div className="tab-content" id="myTabContent3">
											<div className="tab-pane fade show active" id="home2" role="tabpanel"
												aria-labelledby="home2-tab">
												<ul className="list-unstyled" data-simplebar>
													{notifications.map((notification) => (
														<li key={notification.notificationid}>
														<a href={notification.link} className="media media-message media-notification">
														  <div className="position-relative mr-3">
															{/* You can include an image here if available */}
															<span className={`status ${notification.viewed ? 'viewed' : 'away'}`}></span>
														  </div>
														  <div className="media-body d-flex justify-content-between">
															<div className="message-contents">
															  <h4 className="title">{notification.type}</h4>
															  <p className="last-msg">{notification.detail}</p>
															  <span className="font-size-12 font-weight-medium text-secondary">
																{/* <i className="mdi mdi-clock-outline"></i> {timeAgo(notification.createDate)} */}
															  </span>
															</div>
														  </div>
														</a>
													  </li>
													))}
													{/* <li>
														<a href="javscript:void(0)"
															className="media media-message media-notification">
															<div className="position-relative mr-3">
																<span className="status away"></span>
															</div>
															<div className="media-body d-flex justify-content-between">
																<div className="message-contents">
																	<h4 className="title">Nitin</h4>
																	<p className="last-msg">Lorem ipsum dolor sit, amet
																		consectetur adipisicing elit. Nam itaque
																		doloremque odio, eligendi delectus vitae.</p>

																	<span
																		className="font-size-12 font-weight-medium text-secondary">
																		<i className="mdi mdi-clock-outline"></i> 30 min
																		ago...
																	</span>
																</div>
															</div>
														</a>
													</li> */}
												</ul>
											</div>

										</div>
									</div>
								</div>
								)}
							</li>
							<li className="right-sidebar-in right-sidebar-2-menu">
								<i className="mdi mdi-settings-outline mdi-spin"></i>
							</li>
						</ul>
					</div>
				</nav>
			</header>
	 
  );
}

export default Header;
