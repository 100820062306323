import React, { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import "../../style.scss";
import "../../riot_admin.scss";
import SideBar from "../../vendor/sidebar/index";
import VendorHeader from "../../../src/vendor/header";
import axios from "axios";
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logoShopeasey from "../../../src/images/shopeasey.png"

const Paymentreceipt = () => {
    const location = useLocation();
    const [logoUrl, setLogoUrl] = useState('');
    const [amountOfSeller, setAmountOfSeller] = useState(''); 
    const [redirectStatus, setRedirectStatus] = useState("");
    const [clientId, setClientId] = useState(null);
    const { paymentDetails } = location.state || {};
    const pdfRef = useRef();
    const [billFrom, setBillFrom] = useState({
        companyname: "",
        address: "",
        phone: "",
        email: "",
    });
    const [billTo, setBillTo] = useState({
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
    });
    const formattedDate = moment(paymentDetails?.createDate).format('YYYY-MM-DD');

    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const response = await fetch(
                    'https://api.starmallonline.com/api/v1/login/getuserdetails',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                        },
                    }
                );

                if (response.ok) {
                    const responseData = await response.json();
                    setClientId(responseData.id);
                    const geByIdResponse = await fetch(
                        'https://api.starmallonline.com/api/v1/seller/getbyid',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                            },
                            body: JSON.stringify({
                                sellerid: responseData.id,
                            }),
                        }
                    );
                    if (geByIdResponse.ok) {
                        const geByIdData = await geByIdResponse.json();
                        setBillTo({
                            firstname: geByIdData.firstname || "",
                            lastname: geByIdData.lastname || "",
                            businessadress: geByIdData.businessadress || "",
                            mobile: geByIdData.mobile ? geByIdData.mobile : "",
                            email: geByIdData.email || "",
                            sellerid: geByIdData.sellerid || "",
                            planname: geByIdData.planname || "",
                            noofcategories: geByIdData.noofcategories || "",
                            noofproducts: geByIdData.noofproducts || "",
                            activated: geByIdData.activated || "",
                        });
                        console.log('Data from /seller/gebyid:', geByIdData);
                    } else {
                        console.error('Error fetching seller details by id');
                    }
                    const sellerAmountResponse = await fetch(
                        'https://api.starmallonline.com/api/v1/sellerpayment/get',
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                            },
                            body: JSON.stringify({
                                sellerid: responseData.id,
                            }),
                        }
                    );
                    if (sellerAmountResponse.ok) {
                        const responseData = await sellerAmountResponse.json();
                        let totalAmount = 0;
                        let redirectStatus;
    
    responseData.forEach(payment => {
        totalAmount += parseFloat(payment.amount);
        redirectStatus = payment.paymentinfo.redirectStatus;
    });

    setAmountOfSeller(totalAmount);
    setRedirectStatus(redirectStatus);
                    } else {
                        console.error('Failed to fetch payment details for the seller');
                    }
                } else {
                    console.error('Error fetching client details');
                }
            } catch (error) {
                console.error("Error checking authentication:", error);
            }
        };

        fetchClientDetails();
    }, []);
    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await fetch('https://api.starmallonline.com/api/v1/logo/getall',{
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setBillFrom({
                        companyname: data.companyname || "",
                        address: data.address || "",
                        phone: data.phone ? data.phone[0] : "",
                        email: data.email || "",
                        gstnumber: data.gstnumber || "",
                    });
                    setLogoUrl(data.logo);
                } else {
                    console.error('Failed to fetch logo');
                }
            } catch (error) {
                console.error('Error fetching logo:', error);
            }
        };

        fetchLogo();
    }, []);

    const handleDownload1 = () => {
        const contentClone = document.documentElement.cloneNode(true);
        const downloadButton = contentClone.querySelector('button');
        if (downloadButton) {
            downloadButton.remove();
        }
        const image = document.createElement('img');
        image.src = logoUrl;
        image.onload = () => {
            contentClone.appendChild(image);
            html2pdf(contentClone, {
                margin: 10,
                filename: 'payment_receipt.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            });
        };
    };
    const handleDownload= async () => {
        try {
            const content = pdfRef.current; // Target only the content to be included in the PDF
            const canvas = await html2canvas(content, { useCORS: true });
    
            const imgData = canvas.toDataURL('image/png');
    
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });
    
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('payment_receipt.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    return (
        <div className="wrapper">
            <div className="ec-content-wrapper payment-recept-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <button className="" style={{height:"50px",marginTop:"50px"}} onClick={handleDownload}>Download Page</button>
                        </div>
                        <div className="mt-4 trk-order justify-content-center" ref={pdfRef}>
                            <div className="text-center text-lg bg-dark payment-receipt-header">
                                <span className="text-uppercase">Payment Receipt</span>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    {/* <img src={logoShopeasey} alt="Logo" width='200px'/> */}
                                </div>
                                <div className="col-md-4">
                                    Bill From <br/>
                                    <div>
                                        <strong>{billFrom.companyname}</strong><br/>
                                        {billFrom.address}<br/>
                                        Phone: {billFrom.phone}<br/>
                                        Email: {billFrom.email}<br/>
                                        GST:{billFrom?.gstnumber}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    Bill To <br/>
                                    <div>
                                        <strong>{billTo.firstname} {billTo.lastname}</strong><br/>
                                        {billTo.businessadress}<br/>
                                        Phone: {billTo.mobile}<br/>
                                        Email: {billTo.email}<br/>
                                        GST:{billTo?.gstnumber}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 seller-pay-details">
                                <div className="col-md-3">
                                    <span className="text-medium">Recept No:</span>#{billTo.sellerid}
                                </div>
                                <div className="col-md-3">
                                    <span className="text-medium"> Date: </span>{formattedDate}<br/>
                                </div>
                                <div className="col-md-3">
                                    <span className="text-medium">Seller ID:</span> #{billTo.sellerid}<br/>
                                </div>
                                <div className="col-md-3">
                                    <span className="text-medium">Status:</span>{redirectStatus}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="ec-odr-dtl  card-default">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="table-responsive">
                                                    <table className="table table-striped o-tbl">
                                                        <thead className="paymnt-recept-th">
                                                            <tr className="line">
                                                                <td className="col-3 primaricolor-1">Plan Name</td>
                                                                <td className="col-md-6 primaricolor-2">Plan Details</td>
                                                                <td className="col-md-3 primaricolor-1">Amount</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="paymnt-recept-boder">
                                                                <td className="col-3"><strong>{billTo.planname}</strong></td>
                                                                <td className="col-6">
                                                                    <strong>No of Categories: {billTo.noofcategories}</strong>,  
                                                                    <strong>No of Products: {billTo.noofproducts}</strong>
                                                                </td>
                                                                <td className="col-3"><strong>{amountOfSeller}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="col-3"></td>
                                                                <td className="col-6 text-right"><strong>Taxes</strong></td>
                                                                <td className="col-3 text-right"><strong>N/A</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="col-3"></td>
                                                                <td className="col-6 text-right"><strong>Total</strong></td>
                                                                <td className="col-3 text-right"><strong>{amountOfSeller}</strong></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="col-3"></td>
                                                                <td className="col-6 text-right"><strong>Payment Method</strong></td>
                                                                <td className="col-3 text-right"><strong>Online</strong></td>
                                                            </tr>
                                                            <tr className="paymnt-recept-footer">
                                                                <td className="col-3 text-center " colSpan={3}>
                                                                    Note : This receipt is computer generated and no signature is required.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table> 
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default Paymentreceipt;
