import React, { useRef,useState, useEffect } from "react";
import axios from "axios";
import { DatePicker } from "antd";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar,ScatterChart,Scatter,PieChart, Pie,ResponsiveContainer,AreaChart,Area } from 'recharts';
import SideBar from "../vendor/sidebar/index";
import VendorHeader from "../../src/vendor/header";
import { Link } from "react-router-dom";

const SellerDashBoard = () => {
    const token = sessionStorage.getItem('tokenforseller');
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [orders, setOrders] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [data, setData] = useState([]);
    const monthFormat = 'YYYY/MM';
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
          const value = payload[0].value;
          return (
            <div className="custom-tooltip">
              <p>{`Value: ${value}%`}</p>
            </div>
          );
        }
      
        return null;
      };
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(500); // Initial width, can be adjusted as needed

  useEffect(() => {
    const updateChartWidth = () => {
      if (chartRef.current) {
        const width = chartRef.current.offsetWidth;
        setChartWidth(width);
      }
    };

    window.addEventListener('resize', updateChartWidth);
    updateChartWidth(); // Set initial width

    return () => {
      window.removeEventListener('resize', updateChartWidth);
    };
  }, []);
	const data1 = [
		{ year: 'Mon', value: 3 },
		{ year: 'Tue', value: 4 },
		{ year: 'Wed', value: 3.5 },
		{ year: 'Thu', value: 5 },
		{ year: 'Fri', value: 4.9 },
		{ year: 'Sat', value: 6 },
		{ year: 'Sun', value: 7 },
	  ];
      const data2 = [
		{ year: 'Mon', value: 3 },
        { year: 'Tue', value: 4 },
        { year: 'Wed', value: 3.5 },
        { year: 'Thu', value: 5 },
        { year: 'Fri', value: 4.9 },
        { year: 'Sat', value: 6 },
        { year: 'Sun', value: 7 },
	  ];
      const data3 = [
        { name: 'Fashion', value: 4.567, color: '#8884d8' },
        { name: 'Electronics', value: 3.167, color: '#82ca9d' },
        { name: 'Makeup', value: 1.845, color: '#ffc658' },
    ];
    const data4 = [
		{ year: '12am', value: 0 },
        { year: '8am', value: 4 },
        { year: '4pm', value: 3.5 },
        { year: '11pm', value: 5 },
	  ];
      const data5 = [
        {
          name: '1',
          day: 4000,
          pv: 2400,
          amt: 2400,
        },
        {
          name: '2',
          day: 3000,
          pv: 1398,
          amt: 2210,
        },
        {
          name: '3',
          day: -1000,
          pv: 9800,
          amt: 2290,
        },
        {
          name: '4',
          day: 500,
          pv: 3908,
          amt: 2000,
        },
        {
          name: '5',
          day: -2000,
          pv: 4800,
          amt: 2181,
        },
        {
          name: '6',
          day: -250,
          pv: 3800,
          amt: 2500,
        },
        {
          name: '7',
          day: 3490,
          pv: 4300,
          amt: 2100,
        },
      ];
      const data6 = [
        { name: '1', sales: 50, country: 'INDIA'},
        { name: '2', sales: 75, country: 'MALAYSIA' },
        { name: '3', sales: 25, country: 'SINGAPORE' },
      ];
      const ProgressBar = ({ x, y, width, height, sales, country }) => {
        const barColor = sales >= 100 ? 'blue' : 'grey'; // Adjust color based on percentage
        return (
          <g>
            <rect x={x} y={y} width={width} height={height} fill={barColor} />
            <text x={x + width / 2} y={y + height / 2} textAnchor="middle" fill="white">
              {`${country} ${sales}%`}
            </text>
          </g>
        );
      };
      const dashBoardDetails1 = async () => {
        try {
            const formData = new FormData();
            const response = await axios.post(
                'https://api.starmallonline.com/api/v1/dashboard/sellerdashboard',
                formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setData(response.data)
        } catch (error) {
            console.error("Error", error);
        }
    };
    const dashBoardDetails = async () => {
        try {
            const formData = new FormData();
            formData.append('selectedMonth', selectedMonth.format(monthFormat))
            const response = await axios.post(
                'https://api.starmallonline.com/api/v1/dashboard/sellerdashboard',
                formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setData(response.data)
        } catch (error) {
            console.error("Error", error);
        }
    };
    useEffect(() => {
        dashBoardDetails1();
    }, []);
    useEffect(() => {
        dashBoardDetails();
    }, [selectedMonth]);
    useEffect(() => {
        const fetchClientDetails = async () => {
          try {
            const response = await fetch(
              'https://api.starmallonline.com/api/v1/login/getuserdetails',
              {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                },
              }
            );
    
            if (response.ok) {
                const responseData = await response.json();
                setClientId(responseData.id); // Store the client ID in state
              } else {
                console.error('Error fetching client details');
              }
          } catch (error) {
            console.error("Error checking authentication:", error);
          }
        };
    
        fetchClientDetails();
      }, []);
      useEffect(() => {
        if (clientId) {
          const fetchSubData = async () => {
            try {
              const formData = new FormData();
              formData.append('sellerid', clientId);
      
              const response = await axios.post(
                'https://api.starmallonline.com/api/v1/order/getbyselleridrecent',
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenforseller')}`,
                  },
                }
              );
      
              if (response.status === 401 || response.status === 404) {
                // navigate("/login");
                return;
              }
              setOrders(response.data)
              console.log('Response data:', response.data);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchSubData();
        }
      }, [clientId]);
      const calculateDiameter = (value) => {
        const maxDiameter = 160;
        const normalizedValue = value / Math.max(...data3.map(entry => entry.value));
        return maxDiameter * normalizedValue;
      };
      const gradientOffset = () => {
        const dataMax = Math.max(...data5.map((i) => i.day));
        const dataMin = Math.min(...data5.map((i) => i.day));
      
        if (dataMax <= 0) {
          return 0;
        }
        if (dataMin >= 0) {
          return 1;
        }
      
        return dataMax / (dataMax - dataMin);
      };
      const off = gradientOffset();
    return (
        
        <div className="wrapper">
		<SideBar/>
		<div className="ec-page-wrapper">
			<VendorHeader/>
            <div className="row container-fluid">
                <div className="col-md-12">
        <div className="ec-content-wrapper">
            <div className="row mt-1 mb-1">
                <div className="col-md-12 d-flex justify-content-end">
            <DatePicker
                picker="month"
                value={selectedMonth}
                onChange={date => setSelectedMonth(date)}
                placeholder="Select month"
                allowClear={false}
                format={monthFormat}
            />
            </div>
            </div>
			<div className="row">
            <div className="col-xl-3 col-sm-6 lbl-card">
                <div className="card card-mini dash-card card-1">
                    <div className="card-body">
                        <h2 className="mb-1">{data.TotalCatagories}</h2>
                        <p>Total Categories</p>
                        <span className="mdi mdi-account-arrow-left"></span>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 lbl-card">
                <div className="card card-mini dash-card card-2">
                    <div className="card-body">
                        <h2 className="mb-1">{data.TotalProducts}</h2>
                        <p>Total Products</p>
                        <span className="mdi mdi-account-clock"></span>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 lbl-card">
                <div className="card card-mini dash-card card-3">
                    <div className="card-body">
                        <h2 className="mb-1">{data.ActiveProducts}</h2>
                        <p>Active Products</p>
                        <span className="mdi mdi-package-variant"></span>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-sm-6 lbl-card">
                <div className="card card-mini dash-card card-4">
                    <div className="card-body">
                        <h2 className="mb-1">{data.InactiveProducts}</h2>
                        <p>Inactive Products</p>
                        <span className="mdi mdi-currency-usd"></span>
                    </div>
                </div>
            </div>
			</div>
			<div className="row">
				<div className="col-md-4">
                <div className="card ec-cust-card card-table-border-none card-default">
                <div className="card-header justify-content-between ">
                <h2>Total Sales & Cost</h2>
                <p>Last 7 days</p>
                </div>
                <LineChart
    width={500}
    height={300}
    data={data2}
    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    yAxis={null} 
>
    <XAxis dataKey="year" />
    <Tooltip />
    <Legend />
    <Line type="natural" dataKey="value" stroke="#8884d8" dot={false} />
</LineChart>
</div>
                </div>
				
				<div className="col-md-4">
                <div className="card ec-cust-card card-table-border-none card-default"><div className="card-header justify-content-between ">
                <h2>Total Products</h2>
                </div>
			<BarChart
      width={500}
      height={300}
      data={data1}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="year" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
	</div>
    </div>
    <div className="col-md-4">
                <div className="card ec-cust-card card-table-border-none card-default"><div className="card-header justify-content-between ">
                <h2>Discount Products</h2>
                </div>
			<AreaChart
      width={500}
      height={300}
      data={data5}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="green" stopOpacity={1} />
              <stop offset={off} stopColor="red" stopOpacity={1} />
            </linearGradient>
          </defs>
      <Legend />
      <Area type="monotone" dataKey="day" stroke="#000" fill="url(#splitColor)" />
    </AreaChart>
	</div>
    </div>
	</div>
    <div className="row mt-3">
        <div className="col-md-8">
        <div className="card ec-cust-card card-table-border-none card-default">
            <div className="row mb-4">
                <div className="col-md-2 card-header">

                <h2>Reports</h2>
                </div>
                <div className="col-md-2">
                    <div>24K</div>
                    <div>Customers</div>
                </div>
                <div className="col-md-2">
                    <div>3.5K</div>
                    <div>Total Products</div></div>
                <div className="col-md-2">
                    <div>2.5K</div>
                    <div>Stock Products</div></div>
                <div className="col-md-2">
                    <div>0.5K</div>
                    <div>Out of Stock</div></div>
                <div className="col-md-2">
                    <div>250K</div>
                    <div>Revenue</div></div>
            </div>
            <div className="col-md-6" style={{ width: '100%' }}>
      <div ref={chartRef} className="line-chart-container">
        <LineChart
          width={chartWidth}
          height={300}
          data={data1}
          margin={{
              top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
          {/* You can add custom shapes or markers here to represent progress */}
        </LineChart>
      </div>
    </div>
			</div>
            </div>
            <div className="col-md-4">
            <div className="card ec-cust-card card-table-border-none card-default">
                 <div className="card-header justify-content-between ">
            <h2>Sales by Country</h2>
            </div>
            <BarChart width={400} height={300} data={data6} layout="vertical">
      <XAxis type="number" />
      <YAxis type="category" dataKey="name" />
      <Tooltip />
      <Legend />
      <Bar dataKey="sales" shape={<ProgressBar />} />
    </BarChart>
            </div>
            </div>
            </div>
            <div className="row mt-3">
            <div className="col-md-5">
            <div className="card ec-cust-card card-table-border-none card-default">
            <div className="card-header justify-content-between ">
            <h2>Top Selling Category</h2>
          
            <div className="dropdown show d-inline-block widget-dropdown">
											<a className="dropdown-toggle icon-burger-mini" href="#" role="button"
												id="dropdown-customar" data-bs-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false" data-display="static">
											</a>
											<ul className="dropdown-menu dropdown-menu-right">
												<li className="dropdown-item"><a href="#">Action</a></li>
												<li className="dropdown-item"><a href="#">Another action</a></li>
												<li className="dropdown-item"><a href="#">Something else here</a></li>
											</ul>
										</div>
                                        </div>
                                        <ResponsiveContainer width={500} height={300}>
      <PieChart>
        {data3.map((entry, index) => (
          <Pie
            key={`pie-${index}`}
            data={[entry]}
            cx="50%"
            cy="50%"
            innerRadius={0}
            outerRadius={calculateDiameter(entry.value) / 2} // Calculate radius to diameter
            fill={entry.color}
            startAngle={90}
            endAngle={-270}
            label={false}
          />
        ))}
        <Tooltip />
      </PieChart>
      </ResponsiveContainer>
        </div>
        </div>
        <div className="col-md-7">
							<div className="card ec-cust-card card-table-border-none card-default">
								<div className="card-header justify-content-between ">
									<h2>Last Transactions</h2>
									<div>
                                        <Link to="/">View All</Link>
									</div>
								</div>
								<div className="card-body pt-0 pb-15px">
									<table className="table ">
                                    <thead>
        <tr>
          <th>ID</th>
          <th>ISSUED DATE</th>
          <th>TOTAL</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
										<tbody>
											<tr>
												<td>
													<div className="media">
														<div className="media-body align-self-center">
															<a href="profile.html">
																<h6 className="mt-0 text-dark font-weight-medium">#001</h6>
															</a>
														</div>
													</div>
												</td>
												<td>2 Orders</td>
												<td className="text-dark d-none d-md-block">$150</td>
												<td><Link to="/">View Detail</Link></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
            </div>
            <div className="row mt-3">
            <div className="col-md-8">
							<div className="card ec-cust-card card-table-border-none card-default">
								<div className="card-header justify-content-between ">
									<h2>Best Selling Products</h2>
									<div>
										<div className="dropdown show d-inline-block widget-dropdown">
											<a className="dropdown-toggle icon-burger-mini" href="#" role="button"
												id="dropdown-customar" data-bs-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false" data-display="static">
											</a>
											<ul className="dropdown-menu dropdown-menu-right">
												<li className="dropdown-item"><a href="#">Action</a></li>
												<li className="dropdown-item"><a href="#">Another action</a></li>
												<li className="dropdown-item"><a href="#">Something else here</a></li>
											</ul>
										</div>
									</div>
								</div>
								<div className="card-body pt-0 pb-15px">
									<table className="table ">
                                                                        <thead>
                                            <tr>
                                            <th>#</th>
                                            <th>PRODUCT</th>
                                            <th>TOTAL ORDER</th>
                                            <th>STATUS</th>
                                            <th>PRICE</th>
                                            </tr>
                                        </thead>
										<tbody>
											<tr>
												<td>
													<div className="media">
														<div className="media-image mr-3 rounded-circle">
															<a href="profile.html"><img
																	className="profile-img rounded-circle w-45"
																	src="assets/img/user/u1.jpg"
																	alt="customer image"/></a>
														</div>
														<div className="media-body align-self-center">
															<a href="profile.html">
																<h6 className="mt-0 text-dark font-weight-medium">Selena
																	Wagner</h6>
															</a>
															<small>@selena.oi</small>
														</div>
													</div>
												</td>
												<td>2 Orders</td>
												<td className="text-dark d-none d-md-block">$150</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
                <div className="col-md-4">
							<div className="card ec-cust-card card-table-border-none card-default">
								<div className="card-header justify-content-between ">
									<h2>Trending Products</h2>
									<div>
										<div className="dropdown show d-inline-block widget-dropdown">
											<a className="dropdown-toggle icon-burger-mini" href="#" role="button"
												id="dropdown-customar" data-bs-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false" data-display="static">
											</a>
											<ul className="dropdown-menu dropdown-menu-right">
												<li className="dropdown-item"><a href="#">Action</a></li>
												<li className="dropdown-item"><a href="#">Another action</a></li>
												<li className="dropdown-item"><a href="#">Something else here</a></li>
											</ul>
										</div>
									</div>
								</div>
								<div className="card-body pt-0 pb-15px">
									<table className="table ">
										<tbody>
											<tr>
												<td>
													<div className="media">
														<div className="media-image mr-3 rounded-circle">
															<a href="profile.html"><img
																	className="profile-img rounded-circle w-45"
																	src="assets/img/user/u1.jpg"
																	alt="customer image"/></a>
														</div>
													</div>
												</td>
                                                <td>
                                                    
														<div className="media-body align-self-center">
															<a href="profile.html">
																<h6 className="mt-0 text-dark font-weight-medium">Selena
																	Wagner</h6>
															</a>
															<small>@selena.oi</small>
														</div>
                                                </td>
												<td className="text-dark d-none d-md-block">$150</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
				
                </div>
            </div>
            <div className="row mt-3">
            <div className="col-xl-4 ml-3">
            <div className="card ec-cust-card card-table-border-none card-default">
            <div className="card-header justify-content-between ">
									<h2>Total Orders</h2>
                                    <div>
										<div className="dropdown show d-inline-block widget-dropdown">
											<a className="dropdown-toggle icon-burger-mini" href="#" role="button"
												id="dropdown-customar" data-bs-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false" data-display="static">
											</a>
											<ul className="dropdown-menu dropdown-menu-right">
												<li className="dropdown-item"><a href="#">Action</a></li>
												<li className="dropdown-item"><a href="#">Another action</a></li>
												<li className="dropdown-item"><a href="#">Something else here</a></li>
											</ul>
										</div>
									</div>
                                    
                                    </div>
                                    <div className="row">
									<h1 className="col-md-6">16.5K</h1> 
									<p className="col-md-6">6% vs last day</p>
                                    </div>
									<p>Orders Over Time</p>
                <LineChart
    width={500}
    height={300}
    data={data4}
    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    yAxis={null} 
>
    <XAxis dataKey="year" />
    <Tooltip />
    <Legend />
    <Line type="cardinal" dataKey="value" stroke="#8884d8" dot={false} />
</LineChart>
</div>
                </div>
            <div className="col-xl-7">
							<div className="card ec-cust-card card-table-border-none card-default">
								<div className="card-header justify-content-between ">
									<h2>Recent Orders</h2>
									<div>
										<div className="dropdown show d-inline-block widget-dropdown">
											<a className="dropdown-toggle icon-burger-mini" href="#" role="button"
												id="dropdown-customar" data-bs-toggle="dropdown" aria-haspopup="true"
												aria-expanded="false" data-display="static">
											</a>
											<ul className="dropdown-menu dropdown-menu-right">
												<li className="dropdown-item"><a href="#">Action</a></li>
												<li className="dropdown-item"><a href="#">Another action</a></li>
												<li className="dropdown-item"><a href="#">Something else here</a></li>
											</ul>
										</div>
									</div>
								</div>
								<div className="card-body pt-0 pb-15px">
									<table className="table ">
                                    <thead>
        <tr>
            <th>ID</th>
            <th>CUSTOMER</th>
            <th>STATUS</th>
            <th>TOTAL</th>
        </tr>
    </thead>
										<tbody>
                                            {orders.map((order, index) => (
											<tr key={order.orderid}>
												<td>
													<div className="media">
                                                    <div className="media-body align-self-center">
															<a href="profile.html">
																<h6 className="mt-0 text-dark font-weight-medium">#{order.ordernumber}</h6>
															</a>
														</div>
													</div>
												</td>
                                                <td>
                                                <div className="media-body align-self-center">
															<a href="profile.html">
																<h6 className="mt-0 text-dark font-weight-medium">{order.order}</h6>
															</a>
														</div>
                                                </td>
												<td>{order.paymentstatus}</td>
												<td className="text-dark d-none d-md-block">{order.currency} {order.total}</td>
											</tr>
                                            ))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
            </div>
        </div></div>
            </div>
		</div>
		</div>
    );
}

export default SellerDashBoard;
