import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import axios from 'axios';

const DeleteCategoryModal = ({ category, visible, onClose, onDelete }) => {
  const handleDelete = async () => {
    try {
      // Make an API request to delete the category
      const response = await axios.post(
        'https://api.starmallonline.com/api/v1/category/delete',
        {
          categoryid: category.categoryid,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Category deleted successfully
        onDelete(); // Callback to inform the parent component
      } else {
        console.error('Failed to delete category');
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <Modal
      title="Delete Category"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="delete" type="danger" onClick={handleDelete}>
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete the category: {category.categoryname}?</p>
    </Modal>
  );
};

export default DeleteCategoryModal;
