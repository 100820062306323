import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Modal, Form,Input } from 'antd';
import '../style.scss';
import '../riot_admin.scss';
import SideBar from '../sidebar';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
const VendorList = () => {
	const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const [sellerIdToDeactivate, setSellerIdToDeactivate] = useState(null);
    const [selectedSellerDetails, setSelectedSellerDetails] = useState(null); 
    const [isSellerDetailsModalVisible, setIsSellerDetailsModalVisible] = useState(false); 
    const [form] = Form.useForm();
    const columns = [
      {
          title: 'First Name',
          dataIndex: 'firstname',
          key: 'firstname',
      },
      {
          title: 'Last Name',
          dataIndex: 'lastname',
          key: 'lastname',
      },
      {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
      },
      {
          title: 'Mobile Number',
          dataIndex: 'mobile',
          key: 'mobile',
      },
      {
          title: 'Activated',
          dataIndex: 'activated',
          key: 'activated',
          render: (text, record) => (
              <Button
              type={record.activated ? 'default' : 'primary'}
              style={record.activated ? { borderColor: 'red', color: 'red' } :{} }
              onClick={() => {
                setSellerIdToDeactivate(record.sellerid);
                setConfirmationVisible(true);
            }}
            >
              {record.activated ? 'Deactivate' : 'Activate'}
              </Button>
          ),
      },
      {
          title: 'Plan Commence',
          dataIndex: 'plancommence',
          key: 'plancommence',
          render: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
          title: 'Plan Expiry',
          dataIndex: 'planexpiry',
          key: 'planexpiry',
          render: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, row) => (
          <div className="btn-group">
            <a
              href="#"
              className="btn btn-outline-success" style={{width:"100px"}}
              onClick={() => handleView(row.sellerid)}
            >
              View
            </a>
            </div>
            ),
            }
  ];
    useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.post("https://api.starmallonline.com/api/v1/seller/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
      if (response.status === 200) {
        const data = response.data;
        setData(data);
        const orderIdsArray = data.map(order => order.sellerid);
      localStorage.setItem('sellerIds', JSON.stringify(orderIdsArray));
      }
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
      const activeVendors = async () => {
        try {
          const response = await axios.post("https://api.starmallonline.com/api/v1/seller/getactive", {}, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
    
          if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/AdminLogin');
            return; // Exit early to prevent further logic execution
          }
    
          // Update the component state with the new data
          setData(response.data);
        } catch (error) {
          console.error("Error fetching active vendors:", error);
        }
      };
	  const showallVendors = async () => {
        try {
          const response = await axios.post("https://api.starmallonline.com/api/v1/seller/get", {}, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          });
    
          if (response.status === 401 || response.status === 404) {
            // Redirect to the login page for 401 or 404 errors
            navigate('/AdminLogin');
            return; // Exit early to prevent further logic execution
          }
    
          // Update the component state with the new data
          setData(response.data);
        } catch (error) {
          console.error("Error fetching active vendors:", error);
        }
      };
      const deactivateSeller = async (sellerID,act) => {
        const orderIdsArray = JSON.parse(localStorage.getItem('sellerIds'));
      const selectedOrderIndex = orderIdsArray.indexOf(sellerID);
      if (selectedOrderIndex !== -1) {
        localStorage.setItem('selectedSellerIDfordeactivate', sellerID);
      } else {
        console.error("sellerID not found in orderIdsArray");
      }
      try {
        // Retrieve the sellerID from local storage
        const sellerID = localStorage.getItem('selectedSellerIDfordeactivate');
    
        if (!sellerID) {
          console.error("No sellerID found in local storage");
          return;
        }
    
        // Make a POST request to the API endpoint with the sellerID and activate as false
        const response =  await axios.post(
          "https://api.starmallonline.com/api/v1/seller/deactivateactivate",
          { sellerid: sellerID, activated: act },
          {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json',
            },
          }
        );
    
        if (response.status === 200) {
          // Deactivation successful, update local storage or handle as needed
          console.log("Seller deactivated successfully");
          localStorage.removeItem('selectedSellerIDfordeactivate');
          // Additionally, you may want to fetch updated data or perform any other actions
          fetchData();
          console.log("Refreshing the page...");
        } else {
          console.error("Failed to deactivate seller:", response.data);
        }
      } catch (error) {
        console.error("Error deactivating seller:", error);
      }
      };
      const activateSeller = (sellerID) => {
        const orderIdsArray = JSON.parse(localStorage.getItem('sellerIds'));
      const selectedOrderIndex = orderIdsArray.indexOf(sellerID);
      if (selectedOrderIndex !== -1) {
        localStorage.setItem('selectedSellerIDforactivate', sellerID);
      } else {
        console.error("sellerID not found in orderIdsArray");
      }
      };
      const handleConfirmation = async () => {
        setConfirmationVisible(false);
        deactivateSeller(
            sellerIdToDeactivate,
            !data.find(item => item.sellerid === sellerIdToDeactivate).activated
        );
    };
    const handleView = async (sellerId) => {
      try {
          const response = await axios.post("https://api.starmallonline.com/api/v1/seller/getbyid", { sellerid: sellerId }, {
              headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json',
              },
          });

          if (response.status === 200) {
              // Update the state with the details of the selected seller
              setSelectedSellerDetails(response.data);
              setIsSellerDetailsModalVisible(true);
          } else {
              console.error("Failed to fetch seller details:", response.data);
          }
      } catch (error) {
          console.error("Error fetching seller details:", error);
      }
  };
    return (
        <div className="wrapper">
		<SideBar/>
    <div class="ec-page-wrapper">
			<Header/>
            <div className="ec-content-wrapper">
				<div className="content">
        <Modal
    title="Seller Details"
    visible={isSellerDetailsModalVisible}
    onCancel={() => setIsSellerDetailsModalVisible(false)}
    footer={null}
>
{selectedSellerDetails && (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
        <div style={{ fontWeight: 'bold' }}>Seller Code:</div>
        <div>{selectedSellerDetails.sellercode}</div>

        <div style={{ fontWeight: 'bold' }}>First Name:</div>
        <div>{selectedSellerDetails.firstname}</div>

        <div style={{ fontWeight: 'bold' }}>Last Name:</div>
        <div>{selectedSellerDetails.lastname}</div>

        <div style={{ fontWeight: 'bold' }}>Email:</div>
        <div>{selectedSellerDetails.email}</div>

        <div style={{ fontWeight: 'bold' }}>Mobile Number:</div>
        <div>{selectedSellerDetails.mobile}</div>

        <div style={{ fontWeight: 'bold' }}>PAN:</div>
        <div>{selectedSellerDetails.pan}</div>
        <div style={{ fontWeight: 'bold' }}>Aadhar Number:</div>
        <div>{selectedSellerDetails.aadhar}</div>
        <div style={{ fontWeight: 'bold' }}>DOB:</div>
        <div>{selectedSellerDetails.dob}</div>
        <div style={{ fontWeight: 'bold' }}>Business Name:</div>
        <div>{selectedSellerDetails.businessname}</div>
        <div style={{ fontWeight: 'bold' }}>Business Address:</div>
        <div>{selectedSellerDetails.businessadress}</div>
        <div style={{ fontWeight: 'bold' }}>Business Type:</div>
        <div>{selectedSellerDetails.businesstype}</div>
        <div style={{ fontWeight: 'bold' }}>Business Pan:</div>
        <div>{selectedSellerDetails.businesspan}</div>
        <div style={{ fontWeight: 'bold' }}>Account Name:</div>
        <div>{selectedSellerDetails.accname}</div>
        <div style={{ fontWeight: 'bold' }}>Account No:</div>
        <div>{selectedSellerDetails.accnumber}</div>
        <div style={{ fontWeight: 'bold' }}>IFSC CODE:</div>
        <div>{selectedSellerDetails.ifsccode}</div>
        <div style={{ fontWeight: 'bold' }}>Branch:</div>
        <div>{selectedSellerDetails.branch}</div>

        {/* Add other fields similarly */}
    </div>
)}
</Modal>


        <Modal
                        title="Confirm Action"
                        visible={confirmationVisible}
                        onCancel={() => setConfirmationVisible(false)}
                        footer={[
                            <Button key="cancel" onClick={() => setConfirmationVisible(false)}>Cancel</Button>,
                            <Button key="confirm" type="primary" onClick={handleConfirmation}>Confirm</Button>,
                        ]}
                    >
                        Are you sure you want to {data.find(item => item.sellerid === sellerIdToDeactivate)?.activated ? 'deactivate' : 'activate'} this seller?
                    </Modal>
                <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1></h1>
							<p className="breadcrumbs"> <button className="btn btn-primary" onClick={activeVendors}>Active Sellers</button>
							<button className="btn btn-primary" onClick={showallVendors}>Show all</button>
							</p>
					</div>
                    <div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                      <Table dataSource={data} columns={columns} />
									</div>
								</div>
							</div>
						</div>
					</div>
                </div>
                </div>
            </div>
            </div>
    );
}
export default VendorList;